import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../../profile/services/profile.service';
import { Chat, ChatObservable, Message, MessagePagination } from '../models/chat';

const BASE_URL = environment.CHAT_URL;

@Injectable({
   providedIn: 'root'
})
export class ChatService {
   chatHistory: ChatObservable[] = [];
   onlineUsers: number[] = [];

   private chatHistorySource: BehaviorSubject<any> = new BehaviorSubject(null);
   chatHistorySub = this.chatHistorySource.asObservable();

   public isMobileSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
   isMobileSub = this.isMobileSource.asObservable();

   public isChatHistorySource: BehaviorSubject<boolean> = new BehaviorSubject(true);
   isChatHistorySub = this.isChatHistorySource.asObservable();

   private newMessageReceiveSource: BehaviorSubject<any> = new BehaviorSubject(null);
   newMessageReceiveSub = this.newMessageReceiveSource.asObservable();

   private UnreadMessageCountSource: BehaviorSubject<any> = new BehaviorSubject(0);
   UnreadMessageSub = this.UnreadMessageCountSource.asObservable();
   private unreadMessagesCount: number = 0;

   constructor(
      private http: HttpClient,
      private profileService: ProfileService
   ) {}

   addNewChatToHistory(chat: ChatObservable): void {
      const chatHistory = this.chatHistory.find(item => item.roomId === chat.roomId);
      if (chatHistory) {
         chatHistory.messages = chat.messages;
      } else {
         this.chatHistory.unshift(chat);
      }
      this.chatHistorySource.next(this.chatHistory);
   }

   async addNewMessageToChat(message: Message, seen?: boolean): Promise<void> {
      const index = this.chatHistory.findIndex(chat => chat.roomId === message.chatRoomId);

      if (index !== -1) {
         this.chatHistory[index].messages.push(message);
         this.chatHistory[index].seenMessage = seen;
         const chat = { ...this.chatHistory.find(chat => chat.roomId === message.chatRoomId) };
         this.chatHistory.splice(index, 1);
         this.chatHistory.unshift(chat as ChatObservable);
         this.chatHistorySource.next(this.chatHistory);
      } else {
         const user = await this.profileService.getUserInfoByIdPromise(message.senderId);
         const chat = {
            userId: user.id,
            username: user.username,
            name: user.name,
            photo: user.photo as string,
            roomId: message.chatRoomId,
            messages: [message],
            created_at: new Date().toUTCString(),
            role: user.role,
            isOnline: true,
            seenMessage: seen
         };
         this.addNewChatToHistory(chat);
      }
      this.newMessageReceiveSource.next(message);
   }

   addMessagesToChat(messages: Message[], roomId: string): void {
      const chat = this.chatHistory.find(chat => chat.roomId === roomId);
      if (chat) {
         chat.messages = [...messages];
         this.chatHistorySource.next(this.chatHistory);
      }
   }

   sendIsMobile(value: boolean) {
      this.isMobileSource.next(value);
   }

   sendIsChatHistory(value: boolean) {
      this.isChatHistorySource.next(value);
   }

   getChatListHistory(): Observable<Chat[]> {
      return this.http.get<Chat[]>(`${BASE_URL}chat/rooms`);
   }

   newRoom(data: { calledUserId: number }): Observable<{ roomId: string }> {
      return this.http.post<{ roomId: string }>(`${BASE_URL}chat/new-room`, data);
   }

   getChatHistory(roomId: string, pageNumber: number = 0, pageSize: number = 1000): Observable<MessagePagination> {
      return this.http.get<MessagePagination>(
         `${BASE_URL}chat/messages?chatRoomId=${roomId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
   }

   setOnlineUsers(userList: number[]) {
      this.onlineUsers = userList;
      this.chatHistory = this.chatHistory.map(chat => {
         chat.isOnline = userList.includes(chat.userId);
         return chat;
      });
      this.chatHistorySource.next(this.chatHistory);
   }

   setUserStatus(userId: number, online: boolean) {
      if (online) {
         this.onlineUsers.push(userId);
      } else {
         this.onlineUsers = this.onlineUsers.filter(user => user !== userId);
      }
      const chat = this.chatHistory.find(chat => chat.userId === userId);
      if (chat) {
         chat.isOnline = online;
      }
      this.chatHistorySource.next(this.chatHistory);
   }

   isUserOnline(userId: number) {
      return this.onlineUsers.includes(userId);
   }

   addUnreadMessageCount() {
      this.unreadMessagesCount++;
      this.UnreadMessageCountSource.next(this.unreadMessagesCount);
   }
   clearUnreadMessageCount() {
      this.unreadMessagesCount = 0;
      this.UnreadMessageCountSource.next(this.unreadMessagesCount);
   }

   seenChat(roomId: string) {
      const chat = this.chatHistory.find(i => i.roomId === roomId);
      if (chat) chat.seenMessage = true;
      this.chatHistorySource.next(this.chatHistory);
   }
}
